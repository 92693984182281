import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer-blog';
import Navbar from './Navbar';
import './all.sass';
import useSiteMetadata from './SiteMetadata';
import { withPrefix } from 'gatsby';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content="Ortom | Data science consultancy based in Manchester" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="Ortom | Data science consultancy based in Manchester" />
        <meta property="og:image" content="https://ortom.ai/static/introImage-c03eaeff32c738c1810ec9c3f984b22b.png" />
        <meta property="og:url" content="https://ortom.co.uk" />
        <meta property="og:site_name" content="Ortom Ltd." />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar />
      <div className="navbar-padding">{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
