import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import paragraphs from 'lines-to-paragraphs';
import Layout from '../components/LayoutNoFooter';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Content, { HTMLContent } from '../components/Content';
import quote from '../img/quote.png';

export const CaseStudyTemplate = ({
  contentComponent,
  client,
  featuredimage,
  title,
  helmet,
  challengeText,
  solutionText,
  solutionQuote,
  ResultText,
  ResultQuote,
  ConclusionText,
  ConclusionQuote,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-2">
            <p>{client}</p>
          </div>
          <div className="column is-10">
            <h2>{title}</h2>
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            {featuredimage ? (
              <div className="featured-thumbnail">
                {/* TODO: correctly render image */}
                <PreviewCompatibleImage
                  imageInfo={{
                    image: featuredimage,
                    alt: `featured image thumbnail for post ${title}`,
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="column is-10 is-offset-2">
          <h3>Challenge</h3>
        </div>

        <div className="column is-9 is-offset-3">
          <p dangerouslySetInnerHTML={{ __html: paragraphs(challengeText) }} />
        </div>

        <div className="small-gap" />

        <div className="column is-10 is-offset-2">
          <h3>Solution</h3>
        </div>

        <div className="column is-9 is-offset-3">
          <p>{solutionText}</p>
        </div>

        <div className="column is-9 is-offset-3">
          <div className="columns">
            <div className="column is-2">
              <img src={quote} alt="quote" style={{ width: '60px' }} />
            </div>
            <div className="column is-10">
              <h6 className="grey">{solutionQuote}</h6>
            </div>
          </div>
        </div>

        <div className="small-gap" />

        <div className="column is-10 is-offset-2">
          <h3>Result</h3>
        </div>

        <div className="column is-9 is-offset-3">
          <p>{ResultText}</p>
        </div>

        <div className="column is-9 is-offset-3">
          <div className="columns">
            <div className="column is-2">
              <img src={quote} alt="quote" style={{ width: '60px' }} />
            </div>
            <div className="column is-10">
              <h6 className="grey">{ResultQuote}</h6>
            </div>
          </div>
        </div>

        <div className="small-gap" />

        <div className="column is-10 is-offset-2">
          <h3>Conclusion</h3>
        </div>

        <div className="column is-9 is-offset-3">
          <p>{ConclusionText}</p>
        </div>

        <div className="column is-9 is-offset-3">
          <div className="columns">
            <div className="column is-2">
              <img src={quote} alt="quote" style={{ width: '60px' }} />
            </div>
            <div className="column is-10">
              <h6 className="grey">{ConclusionQuote}</h6>
            </div>
          </div>
        </div>

        <div className="small-gap" />
      </div>

      <p className="caseStudy--button">
        <Link className="caseStudy--button" to="/caseStudies">
          ←
          <span className="caseStudy--button">Index</span>
        </Link>
      </p>
    </section>
  );
};

CaseStudyTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const CaseStudy = ({ data }) => {
  const { markdownRemark: post } = data;
  const imagePath = post.frontmatter.featuredimage && `https://ortom.ai${post.frontmatter.featuredimage.childImageSharp.fluid.src}`;

  return (
    <Layout>
      <CaseStudyTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        client={post.frontmatter.client}
        featuredimage={post.frontmatter.featuredimage}
        challengeText={post.frontmatter.challengeText}
        solutionText={post.frontmatter.solutionText}
        solutionQuote={post.frontmatter.solutionQuote}
        ResultText={post.frontmatter.ResultText}
        ResultQuote={post.frontmatter.ResultQuote}
        ConclusionText={post.frontmatter.ConclusionText}
        ConclusionQuote={post.frontmatter.ConclusionQuote}
        helmet={(
          <Helmet titleTemplate="%s | Case Study">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            {/* https://ortom.ai/ */}
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property="og:description" content={post.frontmatter.description} />
            <meta property="og:image" content={post.frontmatter.featuredimage} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="og:image" content={imagePath} />
            <meta property="og:url" content="https://ortom.co.uk" />
            <meta property="og:site_name" content="Ortom Ltd." />
          </Helmet>
        )}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

CaseStudy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CaseStudy;

export const pageQuery = graphql`
  query CaseStudyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        client
        challengeText
        solutionText
        solutionQuote
        ResultText
        ResultQuote
        ConclusionText
        ConclusionQuote
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1277, maxHeight: 718, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
